<template>
  <div class="slider1">
    <div class="container">
      <div class="slider1__items wow animate__animated animate__backInLeft">
        <swiper
          :cssMode="true"
          :slides-per-view="1"
          :autoplay="{
            delay: 5500,
            disableOnInteraction: false,
          }"
          :pagination="{
            clickable: true,
          }"
          :navigation="true"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide
            v-for="(item, index) in this.home.block_2_sliders"
            :key="index"
          >
            <div class="slider1__item">
              <!-- <img
                class="slider1__item__text__bg"
                src="@/assets/images/slide1-bg.png"
                alt=""
              /> -->
              <div class="slider1__item__flex">
                <h1 class="slider1__item__title">{{ item.title }}</h1>
                <img
                  class="slider1__item__img"
                  src="@/assets/images/mobile-img.png"
                  alt=""
                />
              </div>
              <div class="slider1__item__text">
                <img
                  class="slider1__item__text__mob"
                  src="@/assets/images/slider1-mob.png"
                  alt=""
                />
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>

  <MainSliderBtm />

  <MainSlider3 />
</template>

<script>
import axios from "axios";

import MainSliderBtm from "@/components/MainSliderBtm.vue";
import MainSlider3 from "@/components/MainSlider3.vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// Import Swiper Vue.js components
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Pagination, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    MainSliderBtm,
    MainSlider3,
  },
  data() {
    return {
      home: {
        block_1: {
          title: null,
          description: null,
        },
        block_2_sliders: [],
        block_3_sliders: [],
        block_4_sliders: [],
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],

      // centeredSlides: true,
    };
  },
  methods: {
    async getData() {
      try {
        const response = await axios.get(
          "https://admin.housemartinconsulting.com/api/v1/page/home",
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );
        this.home = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.slider1 {
  margin: 34px 0px 120px;
  background-image: url("@/assets/images/slide1-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 0px;

  &__items {
    width: 60%;
  }
  &__item {
    // position: relative;
    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__img {
      display: none;
      object-fit: contain;
    }
    &__text {
      // padding-bottom: 88px;
      position: relative;
      &__mob {
        display: none;
      }

      &__bg {
        object-fit: contain;
        display: block;
        // position: absolute;
        // bottom: 30%;
      }
    }
    &__title {
      color: #1c355e;
      font-size: 60px;
      font-weight: 400;
      line-height: 84px; /* 140% */
      padding-bottom: 65px;
    }
    & p {
      color: #363636;
      font-size: 20px;
      font-weight: 400;
      line-height: 40.8px;
      letter-spacing: 0.2px;
    }
  }
}

@media (max-width: 990px) {
  .slider1 {
    margin: 50px 0px;
    background-size: cover;
    background-image: none;
    &__items {
      width: 100%;
    }
    &__item {
      &__img {
        display: block;
      }
      &__title {
        color: #1c355e;
        font-size: 34px;
        font-weight: 400;
        line-height: 33px; /* 133.333% */
      }
      &__text {
        &__bg {
          display: none;
        }
        & p {
          color: #363636;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.2px;
          // position: absolute;
          // left: 0;
          // bottom: 0;
          // top: 10%;
        }
        &__mob {
          // display: block;
          // position: relative;
          // top: 55px;
          display: none;
        }
      }
    }
  }
}
</style>
