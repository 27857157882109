<template>
  <div class="slider3">
    <div class="container">
      <div class="slider3__items wow animate__animated animate__backInLeft">
        <swiper
          :cssMode="true"
          :slides-per-view="1"
          :navigation="navigation"
          :mousewheel="true"
          :keyboard="true"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide
            v-for="(item, index) in this.home.block_4_sliders"
            :key="index"
          >
            <div class="slider3__item">
              <img src="@/assets/images/slide7.png" alt="" />
              <p>
                {{ item.description }}
              </p>
            </div>
          </swiper-slide>

          <div class="swiper-control flex-row-between-center">
            <div class="swiper-button-prev">
              <img src="../assets/images/left-arrow.svg" alt="" />
            </div>
            <div class="swiper-button-next">
              <img src="../assets/images/right-arrow.svg" alt="" />
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// Import Swiper Vue.js components
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Pagination, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true,
      //   renderBullet: function (index, className) {
      //     return '<span class="' + className + '"></span>';
      //   },
      // },
      home: {
        block_1: {
          title: null,
          description: null,
        },
        block_2_sliders: [],
        block_3_sliders: [],
        block_4_sliders: [],
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      // centeredSlides: true,
    };
  },
  methods: {
    async getData() {
      try {
        const response = await axios.get(
          "https://admin.housemartinconsulting.com/api/v1/page/home",
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );
        this.home = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.slider3 {
  &__item {
    margin: 50px 0px;
    padding: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;

    & img {
      object-fit: contain;
      height: 241px;
    }
    & p {
      color: #363636;
      font-size: 20px;
      font-weight: 400;
      line-height: 40.8px; /* 204% */
      letter-spacing: 0.2px;
      width: 45%;
    }
  }
}

@media (max-width: 990px) {
  .slider3 {
    overflow: hidden;

    &__item {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      & p {
        width: 100%;
        color: #363636;
        font-size: 18px;
        font-weight: 400;
        line-height: 23px; /* 191.667% */
        letter-spacing: 0.2px;
      }
      & img {
        // max-width: 110px;
        // max-height: 107px;
        max-height: 100%;
        width: 100%;
        // padding-bottom: 10px;
      }
    }
  }
}
</style>
