import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";

import "swiper/css";
import "swiper/scss/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/grid";
import { createI18n } from "vue-i18n";

import i18n from "./i18n";
import axios from "axios";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

const lang = localStorage.getItem("currentLanguage") || "en";
axios.defaults.params = {
  lang: lang,
};

// const messages = {
//   en,
//   ru,
//   kz,
// };

// const i18n = createI18n({
//   locale: "ru",
//   fallbackLocale: "ru",
//   messages,
// });

// export default createI18n({
//   locale: `${store.getters.getLang}`,
//   fallbackLocale: `${store.getters.getLang}`,
//   messages: {
//     kz: require("./locales/kz.json"),
//     en: require("./locales/en.json"),
//     ru: require("./locales/ru.json"),
//   },
// });

createApp(App).use(store).use(router).use(i18n).use(vuetify).mount("#app");
