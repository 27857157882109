<template>
  <div id="app">
    <LoaderTop />
    <MainHeader />
    <router-view />
    <MainFooter />
  </div>
</template>

<script>
import MainFooter from "./components/layouts/MainFooter.vue";
import MainHeader from "./components/layouts/MainHeader.vue";
import LoaderTop from "./components/LoaderTop.vue";

export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter,
    LoaderTop,
  },
};
</script>

<style></style>
