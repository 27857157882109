<template>
  <div class="">
    <!-- <pre>{{ this.head }}</pre> -->
    <div class="header">
      <div class="container">
        <div class="header__main">
          <a href="/">
            <img class="header__main__logo" src="@/assets/logo.svg" alt="" />
          </a>
          <div
            class="header__titles"
            v-for="(item, index) in head"
            :key="index"
          >
            <router-link class="header__titles__link" :to="item.slug">{{
              item.title
            }}</router-link>
          </div>

          <div class="header__language">
            <Dropdown>
              <template slot="toggler"> </template>
              <DropdownContent>
                <DropdownItem @click="setLanguagePreference('en')"
                  >EN</DropdownItem
                >
                <DropdownItem @click="setLanguagePreference('ru')"
                  >RU</DropdownItem
                >
              </DropdownContent>
            </Dropdown>
            <!-- <select
              @change="currentLanguage"
              v-model="test1"
              class="header__language__set"
            >
              <option value="ru">RU</option>
              <option value="en">EN</option>
            </select> -->

            <!-- <img src="@/assets/images/globe.svg" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="header__navMobile"
      :class="{ 'header__burger-active': isActive }"
    >
      <div class="header__burger" @click="activeBurger">
        <span class="first"></span>
        <span class="second"></span>
        <span class="third"></span>
      </div>
      <Dropdown>
        <template slot="toggler"> </template>
        <DropdownContent>
          <DropdownItem @click="setLanguagePreference('en')">EN</DropdownItem>
          <DropdownItem @click="setLanguagePreference('ru')">RU</DropdownItem>
        </DropdownContent>
      </Dropdown>
    </div>

    <!-- <div v-if="isActive" class="header__mobile">
      <div
        class="header__mobile__navs"
        v-for="item in mobileMenu"
        :key="item.link"
      >
        <router-link
          class="header__titles__link"
          :to="item.link"
          @click="isActive = false"
          >{{ item.title }}</router-link
        >
      </div>
    </div> -->

    <div v-if="isActive" class="header__mobile">
      <div
        class="header__mobile__navs"
        v-for="(item, index) in head"
        :key="index"
      >
        <router-link
          class="header__titles__link"
          :to="item.slug"
          @click="isActive = false"
          >{{ item.title }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Dropdown from "../Dropdown.vue";
import DropdownContent from "../DropdownContent.vue";
import DropdownItem from "../DropdownItem.vue";

export default {
  components: {
    Dropdown,
    DropdownContent,
    DropdownItem,
  },
  data() {
    return {
      isActive: false,
      test1: localStorage.getItem("currentLanguage") || "en",
      mobileMenu: [
        { title: this.$t("home"), link: "/" },
        { title: this.$t("about"), link: "/about" },
        { title: this.$t("business"), link: "/business" },
        { title: this.$t("services"), link: "/services" },
        { title: this.$t("resourses"), link: "/resourses" },
        { title: this.$t("contactUs"), link: "/contact" },
      ],
      head: [],
    };
  },
  created() {
    this.getHead();
  },
  methods: {
    activeBurger() {
      this.isActive = !this.isActive;
    },
    // currentLanguage() {
    //   localStorage.setItem("currentLanguage", this.test1);
    //   this.$i18n.locale = this.test1;
    //   location.reload();
    // },
    setLanguagePreference(lang) {
      localStorage.setItem("currentLanguage", lang);
      this.$i18n.locale = lang;
      location.reload();
    },
    getHead() {
      axios
        .get(
          `https://admin.housemartinconsulting.com/api/v1/menus?lang=${this.test1}`
        )
        .then((response) => {
          this.head = response.data.menus;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
  // padding: 50px 0px 30px;
  padding: 24px 75px 24px 96px;
  border-bottom: 1px solid #0000001a;
  &__main {
    display: flex;
    &__logo {
      margin-right: 96px;
      max-width: 127px;
      max-height: 100px;
    }
  }
  &__titles {
    display: flex;
    gap: 40px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    &__link {
      text-decoration: none;
      // font-family: Adilet;
      font-weight: 700;
      color: #3571a9;
      font-size: 18px;
      line-height: 24px; /* 133.333% */
      &:focus {
        color: #d05e18;
      }
    }
  }
  &__language {
    // position: absolute;
    // top: 40%;
    right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    &__set {
      // width: 59px;
      // height: 49px;
      height: 25px;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #3571a9;
      align-items: center;
      display: flex;
      justify-content: center;

      & option {
        color: #3571a9;
        font-family: Arial;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
      }
    }
  }
  &__navMobile {
    display: none;
  }
  &__burger {
    display: none;
  }
}

@media (max-width: 1400px) {
  .header {
    &__titles {
      gap: 22px;
    }
  }
}
@media (max-width: 990px) {
  .header {
    border-bottom: none;
    padding: 0;
    &__mobile {
      background-color: #eaf4f6;
      // height: calc(100vh - 52px);
      z-index: 9999;
      width: 100vw;
      padding-top: 231px;
      &__navs {
        // margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      & a {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-bottom: 60px;
      }
    }
    &__main {
      position: relative;
      justify-content: center;
      margin: 0;
      &__logo {
        // width: 97px;
        // height: 74px;

        margin-right: 0px;
      }
    }
    &__titles {
      display: none;
    }
    &__language {
      display: none;
    }
    &__navMobile {
      position: fixed;
      z-index: 9999;
      background: #eaf4f6;
      padding: 10px 20px;
      width: 100%;
      top: 14%;
      right: 0;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__burger {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      // transition: 3s all;
      span {
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background: #024d94;
      }
      &-active {
        // transition: 3s all;
        .first {
          transform: rotate(-45deg);
          position: absolute;
        }
        .second {
          display: none;
        }
        .third {
          transform: rotate(45deg);
        }
      }
    }
  }
}

@font-face {
  font-family: "Adilet";
  font-style: normal;
  src: url("@/assets/fonts/arial_bolditalicmt.ttf");
}
</style>
