<template>
  <div ref="scrollLine" class="scroll-line"></div>
</template>

<script>
export default {
  mounted() {
    this.fillScrollLine();
    window.addEventListener("scroll", this.fillScrollLine);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.fillScrollLine);
  },
  methods: {
    fillScrollLine() {
      const scrollLine = this.$refs.scrollLine;

      if (scrollLine) {
        const windowHeight = window.innerHeight;
        const fullHeight = document.body.clientHeight;
        const scrolled = window.scrollY;
        const percentScrolled = (scrolled / (fullHeight - windowHeight)) * 100;

        scrollLine.style.width = percentScrolled + "%";
      }
    },
  },
};
</script>

<style scoped>
.scroll-line {
  /* background: linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); */
  background: #d9d9d9;
  top: 0;
  left: 0;
  height: 10px;
  position: fixed;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>

<!-- <template>
  <div ref="scrollLine" class="scroll-line"></div>
</template>

<script>
export default {
  mounted() {
    this.fillScrollLine();
    window.addEventListener("scroll", this.fillScrollLine);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.fillScrollLine);
  },
  methods: {
    fillScrollLine() {
      const scrollLine = this.$refs.scrollLine;
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.clientHeight;
      const scrolled = window.scrollY;
      const percentScrolled = (scrolled / (fullHeight - windowHeight)) * 100;

      scrollLine.style.width = percentScrolled + "%";
    },
  },
};
</script>

<style scoped>
.out {
  height: 16px;
  width: 100%;
  background: red;
}
.scroll-line {
  /* background: linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); */
  background: #d9d9d9;
  color: red;
  top: 0;
  left: 0;
  height: 10px;
  position: fixed;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style> -->
