<template>
  <div class="home">
    <LoaderTop />
    <MainBanner />
    <MainSlider />
    <!-- <MainSliderBtm /> -->
    <!-- <MainSlider2 /> -->
  </div>
</template>
<script>
// import CallNow from "@/components/CallNow.vue";
import LoaderTop from "@/components/LoaderTop.vue";
import MainBanner from "@/components/MainBanner.vue";
import MainSlider from "@/components/MainSlider.vue";

export default {
  name: "HomeView",
  components: {
    LoaderTop,
    MainBanner,
    MainSlider,
  },
};
</script>
<style lang="scss" scoped></style>
