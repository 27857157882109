<template>
  <div class="section__opinions container">
    <div class="section__opinions__item">
      <div class="slider2">
        <div class="wow animate__animated animate__backInLeft">
          <img class="slider2__img" src="@/assets/images/slide2.png" alt="" />
        </div>
        <div class="container">
          <div class="slider2__items">
            <swiper
              :cssMode="true"
              :slides-per-view="1"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
              }"
              :pagination="{
                clickable: true,
              }"
              :navigation="true"
              :modules="modules"
              class="mySwiper"
            >
              <swiper-slide
                v-for="(item, index) in this.home.block_3_sliders"
                :key="index"
              >
                <div class="slider2__item">
                  <div class="slider2__item__text">
                    <h1 class="slider2__item__title">
                      {{ item.title }}
                    </h1>
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="section__opinions__item">
      <div class="slider2__items__right">
        <h2 class="slider2__items__right__title">House's Martin Consulting</h2>

        <img
          class="wow slideInLeft animate__animated animate__backInLeft"
          src="@/assets/images/slider2-img.png"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="section__opinions2">
    <div class="section__opinions__item">
      <div class="slider2">
        <div class="wow animate__animated animate__backInLeft">
          <img class="slider2__img" src="@/assets/images/slide2.png" alt="" />
        </div>
        <div class="container">
          <div class="slider2__items">
            <swiper
              :cssMode="true"
              :slides-per-view="1"
              :pagination="{
                clickable: true,
              }"
              :navigation="true"
              :modules="modules"
              class="mySwiper"
            >
              <swiper-slide
                v-for="(item, index) in this.home.block_3_sliders"
                :key="index"
              >
                <div class="slider2__item">
                  <div class="slider2__item__text">
                    <h1 class="slider2__item__title">
                      {{ item.title }}
                    </h1>
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="section__opinions__item">
      <div class="slider2__items__right">
        <h2 class="slider2__items__right__title">House's Martin Consulting</h2>

        <img
          class="wow slideInLeft animate__animated animate__backInLeft"
          src="@/assets/images/slider2-img.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// Import Swiper Vue.js components
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

// SwiperCore.use([Autoplay, Navigation, Pagination, EffectFade]);
SwiperCore.use([Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      home: {
        block_1: {
          title: null,
          description: null,
        },
        block_2_sliders: [],
        block_3_sliders: [],
        block_4_sliders: [],
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      // centeredSlides: true,
    };
  },
  methods: {
    async getData() {
      try {
        const response = await axios.get(
          "https://admin.housemartinconsulting.com/api/v1/page/home",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.home = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
// .swiper-pagination-bullets {
//   bottom: 30% !important;
//   left: -26% !important;
//   width: 100%;
//   padding: 30px 0px;
// }

.section__opinions2 {
  display: none;
}

.section__opinions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__item {
    width: 47%;
    @media (max-width: 990px) {
      width: 100%;
    }
  }
}
.slider2 {
  padding-bottom: 120px;
  &__img {
    // margin-left: 10%;
    margin-bottom: 20px;
    width: 90%;
  }
  &__items {
    // display: flex;
    &__right {
      width: 100%;
      &__title {
        color: #1c355e;
        font-size: 60px;
        font-weight: 400;
        line-height: 84px; /* 140% */
      }
      &__image {
        width: 100%;
      }
    }
  }
  &__item {
    &__text {
      width: 100%;
      padding-bottom: 30px;
      & p {
        width: 80%;
        color: #363636;
        font-size: 20px;
        font-weight: 400;
        line-height: 40.8px; /* 204% */
        letter-spacing: 0.2px;
      }
      &__btn {
        margin-top: 30px;
        display: flex;
        width: 171px;
        padding: 12px 39.47px 11.5px 39.67px;
        justify-content: center;
        align-items: flex-start;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 7px 25px 0px rgba(123, 104, 238, 0.25);
        & button {
          color: #024d94;
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          line-height: 22.5px; /* 150% */
        }
      }
    }
    &__title {
      width: 100%;
      margin-left: auto;
      color: #1c355e;
      font-size: 30px;
      font-weight: 400;
      line-height: 84px; /* 140% */
    }
  }
  &__items {
    &__right {
      & img {
        max-width: 651px;
        max-height: 317px;
      }
    }
  }
}

@media (max-width: 990px) {
  .section__opinions2 {
    display: block;
  }

  .section__opinions {
    display: none;
  }
  .slider2 {
    padding-bottom: 50px;
    &__img {
      margin-left: 0%;
      width: 80%;
    }
    &__items {
      flex-wrap: wrap;
      &__right {
        display: flex;
        flex-direction: column;
        &__title {
          color: #1c355e;
          font-size: 34px;
          font-weight: 400;
          line-height: 34px; /* 113.333% */
          margin-left: auto;
          width: 65%;
          padding-bottom: 26px;
        }
        & img {
          margin-left: auto;

          // height: auto;
          max-width: 100%;
          max-height: 154px;
        }
      }
    }
    &__item {
      &__text {
        width: 100%;
        & p {
          color: #363636;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px; /* 191.667% */
          letter-spacing: 0.2px;
        }
      }
      &__title {
        width: 0%;
      }
    }
  }
}
</style>
