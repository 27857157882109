<template>
  <div class="">
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="footer-col">
            <div class="footer-widget">
              <div class="footer-logo">
                <a href="index.html"
                  ><img src="@/assets/logo.svg" class="img-fluid" alt="logo"
                /></a>
              </div>
              <div class="footer-text">
                <div class="footer-bottom">
                  <div class="footer-bottom-left">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 0.109375C11.4844 0.109375 14.7656 3.39062 14.7656 7.375C14.7656 11.3887 11.4844 14.6406 7.5 14.6406C3.48633 14.6406 0.234375 11.3887 0.234375 7.375C0.234375 3.39062 3.48633 0.109375 7.5 0.109375ZM7.5 13.2344C10.7227 13.2344 13.3594 10.627 13.3594 7.375C13.3594 4.15234 10.7227 1.51562 7.5 1.51562C4.24805 1.51562 1.64062 4.15234 1.64062 7.375C1.64062 10.627 4.24805 13.2344 7.5 13.2344ZM10.6348 10.2754C10.3418 10.5684 9.28711 11.5059 7.58789 11.5059C5.15625 11.5059 3.45703 9.68945 3.45703 7.3457C3.45703 5.03125 5.24414 3.27344 7.55859 3.27344C9.19922 3.27344 10.166 4.03516 10.4297 4.29883C10.4883 4.35742 10.5469 4.44531 10.5469 4.5332C10.5469 4.62109 10.5176 4.67969 10.4883 4.73828L9.93164 5.55859C9.84375 5.73438 9.58008 5.76367 9.43359 5.64648C9.16992 5.44141 8.49609 4.97266 7.61719 4.97266C6.21094 4.97266 5.33203 6.02734 5.33203 7.3457C5.33203 8.54688 6.12305 9.77734 7.64648 9.77734C8.58398 9.77734 9.28711 9.2207 9.55078 8.98633C9.72656 8.83984 9.96094 8.86914 10.0781 9.04492L10.6641 9.83594C10.6934 9.89453 10.7227 9.95312 10.7227 10.041C10.7227 10.1289 10.6934 10.2168 10.6348 10.2754Z"
                        fill="#363636"
                      />
                    </svg>

                    <p>Privacy notice - All Rights Reserved.</p>
                  </div>
                  <div
                    class="footer-bottom-right"
                    v-for="(item, index) in this.agreements"
                    :key="index"
                  >
                    <a :href="item.link" class="footer-bottom-right-link">{{
                      item.title
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-col">
            <h4>{{ $t("home") }}</h4>
            <ul>
              <li>
                <a href="/about">{{ $t("about") }}</a>
              </li>
              <li>
                <a href="/business">{{ $t("business") }}</a>
              </li>
              <li>
                <a href="/services">{{ $t("services") }}</a>
              </li>
              <li>
                <a href="/resourses">{{ $t("resourses") }}</a>
              </li>
              <li>
                <a href="/contact">{{ $t("contactUs") }}</a>
              </li>
            </ul>
          </div>

          <div class="footer-col">
            <div class="footer-col__form">
              <p>
                {{ $t("fillForm") }} <br />
                <span>{{ $t("orderCall") }}</span>
              </p>
              <input
                v-model="name"
                type="text"
                :placeholder="this.$t('firstName')"
              />
              <input
                v-model="last_name"
                type="text"
                :placeholder="this.$t('lastName')"
              />
              <input
                v-mask="'+# ### ### ## ##'"
                type="tel"
                v-model="phone"
                :placeholder="this.$t('enterPhone')"
              />

              <div class="footer-col__form__btn">
                <button @click="sendData()">{{ $t("requestCall") }}</button>
              </div>
            </div>
          </div>

          <div class="footer-col">
            <h4>{{ $t("contactInfo") }}</h4>
            <ul>
              <li>
                <p>{{ $t("phone") }}</p>
              </li>
              <li><a href="tel:87770013456">87770013456</a></li>
              <li>
                <p>{{ $t("email") }}</p>
              </li>
              <li><a href="mailto:mail@htmlacademy.ru">demo@example.com</a></li>
              <li>
                <p>{{ $t("address") }}</p>
              </li>
              <li>
                <a href="tel:87770013456"
                  >526 Melrose Street, Water Mill, 11976 New York</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

    <div class="footer__mobile">
      <div class="container">
        <div class="footer-col">
          <div class="footer-widget">
            <div class="footer-logo">
              <a href="index.html"
                ><img src="@/assets/logo.svg" class="img-fluid" alt="logo"
              /></a>
            </div>
          </div>
        </div>

        <div class="footer-col">
          <div class="footer-col__form">
            <p>
              {{ $t("fillForm") }} <br />
              <span>{{ $t("orderCall") }}</span>
            </p>
            <input
              v-model="name"
              type="text"
              :placeholder="this.$t('firstName')"
            />
            <input
              v-model="last_name"
              type="text"
              :placeholder="this.$t('lastName')"
            />
            <input
              v-mask="'+# ### ### ## ##'"
              type="tel"
              v-model="phone"
              :placeholder="this.$t('enterPhone')"
            />

            <div class="footer-col__form__btn">
              <!-- <button @click="sendData()">{{ $t("requestCall") }}</button> -->
              <button @click="sendData()">{{ $t("submitForm") }}</button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
              >
                <path
                  d="M15.5303 6.57037C15.8232 6.27748 15.8232 5.8026 15.5303 5.50971L10.7574 0.736738C10.4645 0.443845 9.98959 0.443845 9.6967 0.736738C9.40381 1.02963 9.40381 1.50451 9.6967 1.7974L13.9393 6.04004L9.6967 10.2827C9.40381 10.5756 9.40381 11.0504 9.6967 11.3433C9.98959 11.6362 10.4645 11.6362 10.7574 11.3433L15.5303 6.57037ZM0 6.79004L15 6.79004V5.29004L0 5.29004L0 6.79004Z"
                  fill="#077FA0"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="footer-col-flex">
          <div class="footer-col">
            <h4>{{ $t("contactInfo") }}</h4>
            <ul>
              <li>
                <p>{{ $t("phone") }}</p>
              </li>
              <li><a href="tel:87770013456">87770013456</a></li>
              <li>
                <p>{{ $t("email") }}</p>
              </li>
              <li><a href="mailto:mail@htmlacademy.ru">demo@example.com</a></li>
              <li>
                <p>{{ $t("address") }}</p>
              </li>
              <li>
                <a href="tel:87770013456"
                  >526 Melrose Street, Water Mill, 11976 New York</a
                >
              </li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>{{ $t("home") }}</h4>
            <ul>
              <li>
                <a href="/about">{{ $t("about") }}</a>
              </li>
              <li>
                <a href="/business">{{ $t("business") }}</a>
              </li>
              <li>
                <a href="/services">{{ $t("services") }}</a>
              </li>
              <li>
                <a href="/resourses">{{ $t("resourses") }}</a>
              </li>
              <li>
                <a href="/contact">{{ $t("contactUs") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-text">
          <div class="footer-bottom">
            <div class="footer-bottom-left">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0.109375C11.4844 0.109375 14.7656 3.39062 14.7656 7.375C14.7656 11.3887 11.4844 14.6406 7.5 14.6406C3.48633 14.6406 0.234375 11.3887 0.234375 7.375C0.234375 3.39062 3.48633 0.109375 7.5 0.109375ZM7.5 13.2344C10.7227 13.2344 13.3594 10.627 13.3594 7.375C13.3594 4.15234 10.7227 1.51562 7.5 1.51562C4.24805 1.51562 1.64062 4.15234 1.64062 7.375C1.64062 10.627 4.24805 13.2344 7.5 13.2344ZM10.6348 10.2754C10.3418 10.5684 9.28711 11.5059 7.58789 11.5059C5.15625 11.5059 3.45703 9.68945 3.45703 7.3457C3.45703 5.03125 5.24414 3.27344 7.55859 3.27344C9.19922 3.27344 10.166 4.03516 10.4297 4.29883C10.4883 4.35742 10.5469 4.44531 10.5469 4.5332C10.5469 4.62109 10.5176 4.67969 10.4883 4.73828L9.93164 5.55859C9.84375 5.73438 9.58008 5.76367 9.43359 5.64648C9.16992 5.44141 8.49609 4.97266 7.61719 4.97266C6.21094 4.97266 5.33203 6.02734 5.33203 7.3457C5.33203 8.54688 6.12305 9.77734 7.64648 9.77734C8.58398 9.77734 9.28711 9.2207 9.55078 8.98633C9.72656 8.83984 9.96094 8.86914 10.0781 9.04492L10.6641 9.83594C10.6934 9.89453 10.7227 9.95312 10.7227 10.041C10.7227 10.1289 10.6934 10.2168 10.6348 10.2754Z"
                  fill="#363636"
                />
              </svg>

              <p>Privacy notice - All Rights Reserved.</p>
            </div>
            <div
              class="footer-bottom-right"
              v-for="(item, index) in this.agreements"
              :key="index"
            >
              <a :href="item.link" class="footer-bottom-right-link">{{
                item.title
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
export default {
  directives: { mask },
  data() {
    return {
      currentLanguage: localStorage.getItem("currentLanguage"),
      message: {},
      phone: "",
      name: "",
      last_name: "",
      contact: {},
      agreements: [],
    };
  },

  methods: {
    async getData() {
      try {
        const response = await axios.get(
          "https://admin.housemartinconsulting.com/api/v1/page/contacts",
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );

        this.contact = response.data.contact;
        this.agreements = response.data.agreements;
      } catch (error) {
        console.error(error);
      }
    },
    async sendData() {
      const formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("name", this.name);
      formData.append("last_name", this.last_name);

      try {
        const response = await axios.post(
          `https://admin.housemartinconsulting.com/api/v1/applications/create?lang=${this.currentLanguage}`,
          formData,
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );

        this.create = response.data.contact;
        this.agreements = response.data.agreements;
        alert("Заявка отправлена");
        this.name = " ";
        this.phone = " ";
        this.last_name = " ";
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-family: "Jost", sans-serif;
}

.footer-logo {
  margin-bottom: 30px;
  color: #fff;
  @media (max-width: 990px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  font-size: 14px;
  color: #363636;
  line-height: 28px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footer {
  // background-color: #1b1b26;
  background: linear-gradient(
    180deg,
    rgba(7, 127, 160, 0.0858) 59.37%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 70px 0;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
  &__form {
    & p {
      color: #024d94;
      font-size: 20px;
      font-weight: 400;
      line-height: 40.8px; /* 204% */
      letter-spacing: 0.2px;
      margin-bottom: 20px;
      & span {
        color: #d05e18;
        font-size: 15px;
        font-weight: 400;
        line-height: 40.8px; /* 272% */
        letter-spacing: 0.2px;
      }
    }
    & input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      outline: 0;
      // border-bottom: 1px solid red;
      border-color: rgba(0, 0, 0, 0.2);
      margin-bottom: 28px;
      padding: 12px 0px 12px 19px;
      background: white;
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        font-weight: 300;
        line-height: 40.8px; /* 272% */
        letter-spacing: 0.2px;
      }
    }

    &__btn {
      border-radius: 10px;
      background: #024d94;
      display: flex;
      height: 35px;
      padding: 10px 38px 9px 38px;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      & button {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        // line-height: 40.8px; /* 272% */
        letter-spacing: 0.2px;
      }
    }
  }
}
.footer-col h4 {
  font-size: 18px;
  color: #363636;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 150px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  text-transform: capitalize;
  text-decoration: none;
  display: block;
  transition: all 0.3s ease;
  padding: 12px 0px;
  color: #363636;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px; /* 150% */
}
.footer-col ul li a:hover {
  color: #ed5e03;
  padding-left: 8px;
}

.footer-last {
  background: #262636;
}
.footer-bottom {
  justify-content: space-between;
  color: #363636;
  @media (max-width: 990px) {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: 20px;
  }
  &-left {
    display: flex;
    color: #363636;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 20px;
  }
  &-right {
    display: flex;
    gap: 20px;

    &-link {
      color: #363636;
      text-align: right;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px; /* 150% */
      padding-right: 5px;
    }
  }
}

.footer__mobile {
  display: none;
}

/*responsive*/
@media (max-width: 767px) {
  .footer-logo {
    & img {
      padding-top: 40px;
    }
  }
  .footer-col-flex {
    display: flex;
  }
  .footer {
    display: none;
  }
  .footer__mobile {
    display: block;
    background: linear-gradient(
      180deg,
      rgba(7, 127, 160, 0.09) 59.37%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .footer-col h4 {
    color: #d05e18;
    font-size: 15px;
    font-weight: 700;
    line-height: 28px; /* 186.667% */
  }
  .footer-col {
    width: 100%;
    margin-bottom: 30px;
    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & input {
        width: 80%;
      }
      & p {
        text-align: center;
        line-height: 0px; /* 150% */
      }
    }

    & ul {
      & li {
        & a {
          color: #363636;
          font-size: 12px;
          font-weight: 400;
          line-height: 22.5px; /* 187.5% */
          padding: 0;
        }
        & p {
          color: #363636;
          font-size: 12px;
          font-weight: 600;
          line-height: 22.4px; /* 186.667% */
        }
      }
    }
  }

  .footer-bottom {
    padding: 14px;
  }
  .footer-bottom-flex {
    display: flex;
  }

  .footer-col__form__btn {
    display: flex;
    background: none;
    margin-left: auto;
    gap: 20px;
    & button {
      color: #024d94;
      font-family: Arial;
      font-size: 15px;
      font-weight: 700;
      line-height: 22.4px; /* 149.333% */
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }
  }
}
</style>
