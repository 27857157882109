<template>
  <div @click="toggle" v-on-clickaway="away" class="dropdown">
    <slot name="toggler">
      <button>
        <svg
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4997 23.4167C18.2526 23.4167 22.9163 18.753 22.9163 13C22.9163 7.24704 18.2526 2.58334 12.4997 2.58334C6.74671 2.58334 2.08301 7.24704 2.08301 13C2.08301 18.753 6.74671 23.4167 12.4997 23.4167Z"
            stroke="#3571A9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.08301 13H22.9163"
            stroke="#3571A9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.4997 2.58334C15.1052 5.43579 16.5859 9.13754 16.6663 13C16.5859 16.8625 15.1052 20.5642 12.4997 23.4167C9.89417 20.5642 8.41347 16.8625 8.33301 13C8.41347 9.13754 9.89417 5.43579 12.4997 2.58334V2.58334Z"
            stroke="#3571A9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </slot>
    <slot />
  </div>
</template>

<script>
import { mixin as clickaway } from "vue3-click-away";

export default {
  name: "Dropdown",
  mixins: [clickaway],
  provide() {
    return {
      sharedState: this.sharedState,
    };
  },
  data() {
    return {
      sharedState: {
        active: false,
      },
    };
  },
  methods: {
    toggle() {
      this.sharedState.active = !this.sharedState.active;
    },
    away() {
      this.sharedState.active = false;
    },
  },
};
</script>

<style>
.dropdown {
  position: relative;
}
</style>
