import { createStore } from "vuex";

export default createStore({
  state: {
    lang: localStorage.getItem("currentLanguage") || "ru",
  },
  getters: {
    getLang: (state) => state.lang,
  },
  mutations: {
    UPDATE_LANG(state, lang) {
      state.lang = lang;
      localStorage.setItem("currentLanguage", lang);
    },
  },
  actions: {},
  modules: {},
});
