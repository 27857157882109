<template>
  <div class="mainBanner">
    <div class="container">
      <div class="mainBanner__content">
        <div class="mainBanner__content-left">
          <img
            class="mainBanner__content-img"
            src="@/assets/images/mainBanner1-img.png"
            alt=""
          />

          <div class="mainBanner__content-buttons">
            <router-link to="/services">
              <button class="mainBanner__content__service">
                {{ $t("ourServices") }}
              </button>
            </router-link>
            <router-link to="/contact">
              <button class="mainBanner__content__contact">
                {{ $t("contactUs") }}
              </button>
            </router-link>
          </div>
        </div>
        <div
          class="mainBanner__content-right wow animate__animated animate__backInLeft"
        >
          <p>
            {{ home.block_1.description }}
          </p>
          <h3>{{ home.block_1.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      currentLanguage: localStorage.getItem("currentLanguage"),
      home: {
        block_1: {
          title: null,
          description: null,
        },
        block_2_sliders: [],
        block_3_sliders: [],
        block_4_sliders: [],
      },
    };
  },
  methods: {
    async getData() {
      try {
        const response = await axios.get(
          `https://admin.housemartinconsulting.com/api/v1/page/home?lang=${this.currentLanguage}`,
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );
        this.home = response.data;

        console.log(this.currentLanguage);
        console.log(this.home);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.mainBanner {
  background-color: linear-gradient(
    180deg,
    rgba(208, 94, 24, 0.08) 0%,
    rgba(28, 53, 94, 0.08) 100%
  );
  background-image: url("@/assets/images/bg-img.png");
  padding-bottom: 100px;
  position: relative;
  // background: linear-gradient(
  //   350deg,
  //   rgba(255, 255, 255, 0.17) 12.13%,
  //   rgba(2, 77, 148, 0.09) 64.38%
  // );
  // background: #fff;
  padding-top: 200px;
  // background-image: url("@/assets/images/mainBanner-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__content {
    display: flex;
    gap: 150px;
    // margin: 0px 100px;
    &-left {
      & img {
        width: 100%;
      }
    }
    &-right {
      // animation-timeline: scroll();
      // animation-name: slideLeft;
      // animation-duration: auto;
      // animation-timing-function: linear;
      width: 50%;
      & p {
        color: #363636;
        font-size: 20px;
        font-weight: 400;
        line-height: 40.8px; /* 204% */
        letter-spacing: 0.2px;
      }
      & h3 {
        color: #ed5e03;
        font-size: 30px;
        font-weight: 600;
        line-height: 28.8px; /* 96% */
        letter-spacing: 0.2px;
        margin-top: 10px;
      }
      & img {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 5px;
        // background: lightgray 0px 0px / 100% 100% no-repeat;
      }
    }
    &-buttons {
      display: flex;
      column-gap: 24px;
      margin-top: 70px;
    }
    &__service {
      padding: 12px 40px;
      color: #024d94;
      border: #024d94;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 22.5px; /* 150% */
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 7px 25px 0px rgba(123, 104, 238, 0.25);
      cursor: pointer;
    }
    &__contact {
      @extend .mainBanner__content__service;
      color: #fff;
      background: #d05e18;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .mainBanner {
    padding-bottom: 0px;
    &__content {
      flex-wrap: wrap;
      gap: 50px;
      &__service {
        padding: 12px 39px;
      }
      &-buttons {
        margin-top: 50px;
      }
      &-right {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        & h3 {
          color: #ed5e03;
          font-size: 18px;
          font-weight: 600;
          line-height: 28.8px; /* 192% */
          letter-spacing: 0.2px;
          margin-bottom: 10px;
        }
        & p {
          color: #363636;
          font-size: 18px;
          font-weight: 400;
          line-height: 23.3px; /* 194.167% */
          letter-spacing: 0.2px;
        }
      }
      &__contact {
        padding: 12px 28px;
      }
    }
  }
}
</style>
